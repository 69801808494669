// ********************
// SECTION
// ********************

.section {
  position: relative;

  &--full {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
  }
}
