// ********************
// OUR PEOPLE
// ********************

.our-people__heading {
  @extend .hero__heading;
  margin-bottom: unset;
}

.people__heading {
  @extend h3;
  margin-bottom: 1.25rem;
}

.wrapper--staff,
.wrapper--board,
.wrapper--committee {
  margin-bottom: 2.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 5rem;
  }
}

.people__wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(auto, minmax(0, 1fr));
  gap: 1.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(auto, minmax(0, 1fr));
  }
}

.people__card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem;
  border: 2px solid map-get($slate, "100");
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba($black, 0.1),
    0 4px 6px -4px rgba($black, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    border-color: $amaranth;
    box-shadow: 0 25px 50px -12px rgba($black, 0.25);

    .people__card__heading {
      color: $amaranth;
    }
  }
}

.people__card__image {
  width: 3.75rem;
  height: 3.75rem;
  border: 4px solid $white;
  border-radius: 100vw;
  outline: 1px solid $amaranth;
  box-shadow: 0 4px 6px -1px rgba($black, 0.1), 0 2px 4px -2px rgba($black, 0.1);
  margin-right: 1.25rem;
}

.people__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.people__card__heading {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 500;
}

.people__card__title {
  font-size: 1rem;
  line-height: 1;
  color: map-get($slate, "300");
}
