// ********************
// HEADER
// ********************

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

// ********************
// MAIN NAVIGATION
// ********************

.nav {
  display: grid;
  place-items: center;
  width: 100%;
  height: 4.5rem;
  background-color: $white;
  transition: box-shadow 0.3s ease-in-out;

  &.is-scrolled {
    box-shadow: 0 10px 15px -3px rgba($black, 0.1),
      0 4px 6px -4px rgba($black, 0.1);
  }
}

.nav__container {
  @extend .container;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__menu {
  display: none;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.nav__logo {
  width: auto;
  height: 2.25rem;
}

.nav__link-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav__link {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  font-size: map-get($text-size, "300");
  line-height: 1.33rem;
  font-weight: 600;
  color: map-get($slate, "500");
  border-radius: 0.25rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: map-get($slate, "50");
  }

  &--membership {
    @extend .nav__link;
    color: $amaranth;
  }
}

.nav__right {
  display: none;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.nav__btn {
  @extend .btn;
  @extend .btn--primary;
  width: unset;
  max-width: unset;
  min-height: 1.75rem;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown__menu {
  position: absolute;
  top: calc(100% + 1.25rem);
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  width: max-content;
  padding: 1rem;
  border-radius: 0.75rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: $white;
  border: 2px solid map-get($slate, "100");
  box-shadow: 0 4px 6px -1px rgba($black, 0.1), 0 2px 4px -2px rgba($black, 0.1);
}

.dropdown__item {
  @extend .nav__link;
  margin: unset;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropdown__item__icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.dropdown__teaser {
  font-size: 0.875rem;
  line-height: 1;
  color: map-get($slate, "400");
}

.dropdown__link {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.dropdown__icon {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  margin-top: -0.25rem;
}

// ********************
// MOBILE NAVIGATION
// ********************

.nav__hamburger,
.nav__mobile-close {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav__hamburger {
  border: 1px solid map-get($slate, "100");
  border-radius: 0.25rem;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: $amaranth;
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: none;
  }
}

.nav__logo--hamburger {
  width: auto;
  height: 1.5rem;
}

.nav__mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.9);
  backdrop-filter: blur(4px) saturate(110%);
}

.nav__mobile-close {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.nav__mobile-close-icon {
  width: auto;
  height: 1.5rem;
}

.nav__link--mobile {
  display: block;
  margin-block: map-get($spacing, "400");
  font-size: map-get($text-size, "700");
  text-align: center;
  transition: color 0.3s;

  &:hover {
    color: $amaranth;
  }
}

.dropdown__item--mobile {
  display: block;
  text-align: center;

  &:not(:last-of-type) {
    margin-bottom: map-get($spacing, "400");
  }
}

// ********************
// EVENTS CALENDAR
// ********************

.my-events-header {
  display: none;
}
