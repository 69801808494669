// ********************
// BUTTONS
// ********************

.btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: map-get($spacing, "400");
  padding-inline: map-get($spacing, "500");
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  white-space: no-wrap;
  cursor: pointer;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    width: auto;
  }

  &:hover {
    transform: translateY(-2px);
  }

  &--primary {
    color: white !important;
    background-color: $amaranth;
    border: 1px solid $amaranth;
  }

  &--secondary {
    color: $amaranth;
    background-color: $white;
    border: 1px solid $amaranth;
  }
}

.wp-block-button__link {
  @extend .btn;
  @extend .btn--primary;
}
