.course__wrapper {
  display: grid;
  grid-template-rows: repeat(7, minmax(0, min-content));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: flex-start;
  grid-gap: 1.875rem;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    grid-template-rows: repeat(3, minmax(0, min-content));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.course__card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem;
  border: 2px solid map-get($slate, "100");
  border-radius: 0.75rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 25px 50px -12px rgba($black, 0.25);
    border-color: $amaranth;
  }
}

.course__card__thumbnail {
  width: 100%;
  height: auto;
  box-shadow: 0 10px 15px -3px rgba($black, 0.1),
    0 4px 6px -4px rgba($black, 0.1);
  object-fit: cover;
}
