// ********************
// TOGGLE
// ********************

.toggle-wrapper {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 4rem;
  height: 2rem;
  margin: 0 1rem;
  padding: 0.25rem;
  background-color: map-get($slate, "100");
  border-radius: 100vw;
  cursor: pointer;
  transform: translateX(0);
  transition: background-color 0.3s ease-in-out;

  &.is-red {
    background-color: $amaranth;
  }
}

.toggle__flag {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  transition: color 0.3s ease-in-out;

  &.active {
    color: $amaranth;
  }
}

.toggle__btn {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  background-color: $white;
  border-radius: 100vw;
  box-shadow: 0 4px 6px -1px rgba($black, 0.1), 0 2px 4px -2px rgba($black, 0.1);
  transition: transform 0.3s ease-in-out;

  &.is-clicked {
    transform: translateX(100%);
  }
}
