/*!
 Theme Name: Edmonton
 Description: A custom WordPress theme created for the Outdoor Council of Canada.
 Author: MIST Digital
 Author URI: https://mist.rocks/
 Version: 1.1
*/
*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor);
  /* 2 */
}

::before,
::after {
  --tw-content: "";
}

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: theme("fontFamily.sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  /* 4 */
}

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
.person__heading,
h3,
.people__heading,
.projects__heading,
._form-title,
h4,
.resources__heading,
.courses__heading,
h5,
.deal__heading,
.hero__subheading,
.section__subheading,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: theme("fontFamily.mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace);
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 1 */
  background-color: transparent;
  /* 2 */
  background-image: none;
  /* 2 */
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
.person__heading,
h3,
.people__heading,
.projects__heading,
._form-title,
h4,
.resources__heading,
.courses__heading,
h5,
.deal__heading,
.hero__subheading,
.section__subheading,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: theme("colors.gray.400", #9ca3af);
  /* 2 */
}

button,
[role=button] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.is-white {
  color: #ffffff;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-col-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.grid-cols-6 {
  display: grid;
  gap: clamp(0.75rem, 0.62rem + 0.33vw, 0.94rem);
}
@media screen and (max-width: 69.4375rem) {
  .grid-cols-6 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(6, minmax(0, min-content));
  }
}
@media screen and (min-width: 69.5rem) {
  .grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-5 {
  display: grid;
  gap: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
}
@media screen and (max-width: 69.4375rem) {
  .grid-cols-5 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, min-content));
  }
}
@media screen and (min-width: 69.5rem) {
  .grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-4 {
  display: grid;
  gap: clamp(1.5rem, 1.24rem + 0.66vw, 1.88rem);
}
@media screen and (max-width: 69.4375rem) {
  .grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(4, minmax(0, min-content));
  }
}
@media screen and (min-width: 69.5rem) {
  .grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-3 {
  display: grid;
  gap: clamp(2rem, 1.65rem + 0.88vw, 2.5rem);
}
@media screen and (max-width: 69.4375rem) {
  .grid-cols-3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, min-content));
  }
}
@media screen and (min-width: 69.5rem) {
  .grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.grid-cols-2 {
  display: grid;
  gap: clamp(3rem, 2.47rem + 1.32vw, 3.75rem);
}
@media screen and (max-width: 69.4375rem) {
  .grid-cols-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, min-content));
  }
}
@media screen and (min-width: 69.5rem) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.col-span-6 {
  grid-column: span 1/span 1;
}
@media screen and (min-width: 69.5rem) {
  .col-span-6 {
    grid-column: span 6/span 6;
  }
}

.col-span-4 {
  grid-column: span 1/span 1;
}
@media screen and (min-width: 69.5rem) {
  .col-span-4 {
    grid-column: span 4/span 4;
  }
}

.col-span-3 {
  grid-column: span 1/span 1;
}
@media screen and (min-width: 69.5rem) {
  .col-span-3 {
    grid-column: span 3/span 3;
  }
}

.col-span-2 {
  grid-column: span 1/span 1;
}
@media screen and (min-width: 69.5rem) {
  .col-span-2 {
    grid-column: span 2/span 2;
  }
}

.is-truncated {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes horizontalScroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-175rem);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 25px rgba(239, 61, 67, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0px rgba(239, 61, 67, 0);
  }
}
.mx-auto {
  margin: 0 auto;
}

.my-0 {
  margin: 0 0;
}

.my-1 {
  margin: 0.125rem 0;
}
@media screen and (min-width: 64rem) {
  .my-1 {
    margin: 0.25rem 0;
  }
}

.my-2 {
  margin: 0.25rem 0;
}
@media screen and (min-width: 64rem) {
  .my-2 {
    margin: 0.5rem 0;
  }
}

.my-3 {
  margin: 0.375rem 0;
}
@media screen and (min-width: 64rem) {
  .my-3 {
    margin: 0.75rem 0;
  }
}

.my-4 {
  margin: 0.5rem 0;
}
@media screen and (min-width: 64rem) {
  .my-4 {
    margin: 1rem 0;
  }
}

.my-5 {
  margin: 0.625rem 0;
}
@media screen and (min-width: 64rem) {
  .my-5 {
    margin: 1.25rem 0;
  }
}

.my-6 {
  margin: 0.75rem 0;
}
@media screen and (min-width: 64rem) {
  .my-6 {
    margin: 1.5rem 0;
  }
}

.my-7 {
  margin: 0.875rem 0;
}
@media screen and (min-width: 64rem) {
  .my-7 {
    margin: 1.75rem 0;
  }
}

.my-8 {
  margin: 1rem 0;
}
@media screen and (min-width: 64rem) {
  .my-8 {
    margin: 2rem 0;
  }
}

.my-9 {
  margin: 1.125rem 0;
}
@media screen and (min-width: 64rem) {
  .my-9 {
    margin: 2.25rem 0;
  }
}

.my-10 {
  margin: 1.25rem 0;
}
@media screen and (min-width: 64rem) {
  .my-10 {
    margin: 2.5rem 0;
  }
}

.my-11 {
  margin: 1.375rem 0;
}
@media screen and (min-width: 64rem) {
  .my-11 {
    margin: 2.75rem 0;
  }
}

.my-12 {
  margin: 1.5rem 0;
}
@media screen and (min-width: 64rem) {
  .my-12 {
    margin: 3rem 0;
  }
}

.my-14 {
  margin: 1.75rem 0;
}
@media screen and (min-width: 64rem) {
  .my-14 {
    margin: 3.5rem 0;
  }
}

.my-16 {
  margin: 2rem 0;
}
@media screen and (min-width: 64rem) {
  .my-16 {
    margin: 4rem 0;
  }
}

.my-18 {
  margin: 2.25rem 0;
}
@media screen and (min-width: 64rem) {
  .my-18 {
    margin: 4.5rem 0;
  }
}

.my-20 {
  margin: 2.5rem 0;
}
@media screen and (min-width: 64rem) {
  .my-20 {
    margin: 5rem 0;
  }
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.125rem;
}
@media screen and (min-width: 64rem) {
  .mt-1 {
    margin-top: 0.25rem;
  }
}

.mt-2 {
  margin-top: 0.25rem;
}
@media screen and (min-width: 64rem) {
  .mt-2 {
    margin-top: 0.5rem;
  }
}

.mt-3 {
  margin-top: 0.375rem;
}
@media screen and (min-width: 64rem) {
  .mt-3 {
    margin-top: 0.75rem;
  }
}

.mt-4 {
  margin-top: 0.5rem;
}
@media screen and (min-width: 64rem) {
  .mt-4 {
    margin-top: 1rem;
  }
}

.mt-5 {
  margin-top: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .mt-5 {
    margin-top: 1.25rem;
  }
}

.mt-6 {
  margin-top: 0.75rem;
}
@media screen and (min-width: 64rem) {
  .mt-6 {
    margin-top: 1.5rem;
  }
}

.mt-7 {
  margin-top: 0.875rem;
}
@media screen and (min-width: 64rem) {
  .mt-7 {
    margin-top: 1.75rem;
  }
}

.mt-8 {
  margin-top: 1rem;
}
@media screen and (min-width: 64rem) {
  .mt-8 {
    margin-top: 2rem;
  }
}

.mt-9 {
  margin-top: 1.125rem;
}
@media screen and (min-width: 64rem) {
  .mt-9 {
    margin-top: 2.25rem;
  }
}

.mt-10 {
  margin-top: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .mt-10 {
    margin-top: 2.5rem;
  }
}

.mt-11 {
  margin-top: 1.375rem;
}
@media screen and (min-width: 64rem) {
  .mt-11 {
    margin-top: 2.75rem;
  }
}

.mt-12 {
  margin-top: 1.5rem;
}
@media screen and (min-width: 64rem) {
  .mt-12 {
    margin-top: 3rem;
  }
}

.mt-14 {
  margin-top: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .mt-14 {
    margin-top: 3.5rem;
  }
}

.mt-16 {
  margin-top: 2rem;
}
@media screen and (min-width: 64rem) {
  .mt-16 {
    margin-top: 4rem;
  }
}

.mt-18 {
  margin-top: 2.25rem;
}
@media screen and (min-width: 64rem) {
  .mt-18 {
    margin-top: 4.5rem;
  }
}

.mt-20 {
  margin-top: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .mt-20 {
    margin-top: 5rem;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.125rem;
}
@media screen and (min-width: 64rem) {
  .mb-1 {
    margin-bottom: 0.25rem;
  }
}

.mb-2 {
  margin-bottom: 0.25rem;
}
@media screen and (min-width: 64rem) {
  .mb-2 {
    margin-bottom: 0.5rem;
  }
}

.mb-3 {
  margin-bottom: 0.375rem;
}
@media screen and (min-width: 64rem) {
  .mb-3 {
    margin-bottom: 0.75rem;
  }
}

.mb-4 {
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 64rem) {
  .mb-4 {
    margin-bottom: 1rem;
  }
}

.mb-5 {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .mb-5 {
    margin-bottom: 1.25rem;
  }
}

.mb-6 {
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 64rem) {
  .mb-6 {
    margin-bottom: 1.5rem;
  }
}

.mb-7 {
  margin-bottom: 0.875rem;
}
@media screen and (min-width: 64rem) {
  .mb-7 {
    margin-bottom: 1.75rem;
  }
}

.mb-8 {
  margin-bottom: 1rem;
}
@media screen and (min-width: 64rem) {
  .mb-8 {
    margin-bottom: 2rem;
  }
}

.mb-9 {
  margin-bottom: 1.125rem;
}
@media screen and (min-width: 64rem) {
  .mb-9 {
    margin-bottom: 2.25rem;
  }
}

.mb-10 {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .mb-10 {
    margin-bottom: 2.5rem;
  }
}

.mb-11 {
  margin-bottom: 1.375rem;
}
@media screen and (min-width: 64rem) {
  .mb-11 {
    margin-bottom: 2.75rem;
  }
}

.mb-12 {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 64rem) {
  .mb-12 {
    margin-bottom: 3rem;
  }
}

.mb-14 {
  margin-bottom: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .mb-14 {
    margin-bottom: 3.5rem;
  }
}

.mb-16 {
  margin-bottom: 2rem;
}
@media screen and (min-width: 64rem) {
  .mb-16 {
    margin-bottom: 4rem;
  }
}

.mb-18 {
  margin-bottom: 2.25rem;
}
@media screen and (min-width: 64rem) {
  .mb-18 {
    margin-bottom: 4.5rem;
  }
}

.mb-20 {
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .mb-20 {
    margin-bottom: 5rem;
  }
}

.py-0 {
  padding: 0 0;
}

.py-1 {
  padding: 0.125rem 0;
}
@media screen and (min-width: 64rem) {
  .py-1 {
    padding: 0.25rem 0;
  }
}

.py-2 {
  padding: 0.25rem 0;
}
@media screen and (min-width: 64rem) {
  .py-2 {
    padding: 0.5rem 0;
  }
}

.py-3 {
  padding: 0.375rem 0;
}
@media screen and (min-width: 64rem) {
  .py-3 {
    padding: 0.75rem 0;
  }
}

.py-4 {
  padding: 0.5rem 0;
}
@media screen and (min-width: 64rem) {
  .py-4 {
    padding: 1rem 0;
  }
}

.py-5 {
  padding: 0.625rem 0;
}
@media screen and (min-width: 64rem) {
  .py-5 {
    padding: 1.25rem 0;
  }
}

.py-6 {
  padding: 0.75rem 0;
}
@media screen and (min-width: 64rem) {
  .py-6 {
    padding: 1.5rem 0;
  }
}

.py-7 {
  padding: 0.875rem 0;
}
@media screen and (min-width: 64rem) {
  .py-7 {
    padding: 1.75rem 0;
  }
}

.py-8 {
  padding: 1rem 0;
}
@media screen and (min-width: 64rem) {
  .py-8 {
    padding: 2rem 0;
  }
}

.py-9 {
  padding: 1.125rem 0;
}
@media screen and (min-width: 64rem) {
  .py-9 {
    padding: 2.25rem 0;
  }
}

.py-10 {
  padding: 1.25rem 0;
}
@media screen and (min-width: 64rem) {
  .py-10 {
    padding: 2.5rem 0;
  }
}

.py-11 {
  padding: 1.375rem 0;
}
@media screen and (min-width: 64rem) {
  .py-11 {
    padding: 2.75rem 0;
  }
}

.py-12 {
  padding: 1.5rem 0;
}
@media screen and (min-width: 64rem) {
  .py-12 {
    padding: 3rem 0;
  }
}

.py-14 {
  padding: 1.75rem 0;
}
@media screen and (min-width: 64rem) {
  .py-14 {
    padding: 3.5rem 0;
  }
}

.py-16 {
  padding: 2rem 0;
}
@media screen and (min-width: 64rem) {
  .py-16 {
    padding: 4rem 0;
  }
}

.py-18 {
  padding: 2.25rem 0;
}
@media screen and (min-width: 64rem) {
  .py-18 {
    padding: 4.5rem 0;
  }
}

.py-20 {
  padding: 2.5rem 0;
}
@media screen and (min-width: 64rem) {
  .py-20 {
    padding: 5rem 0;
  }
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.125rem;
}
@media screen and (min-width: 64rem) {
  .pt-1 {
    padding-top: 0.25rem;
  }
}

.pt-2 {
  padding-top: 0.25rem;
}
@media screen and (min-width: 64rem) {
  .pt-2 {
    padding-top: 0.5rem;
  }
}

.pt-3 {
  padding-top: 0.375rem;
}
@media screen and (min-width: 64rem) {
  .pt-3 {
    padding-top: 0.75rem;
  }
}

.pt-4 {
  padding-top: 0.5rem;
}
@media screen and (min-width: 64rem) {
  .pt-4 {
    padding-top: 1rem;
  }
}

.pt-5 {
  padding-top: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .pt-5 {
    padding-top: 1.25rem;
  }
}

.pt-6 {
  padding-top: 0.75rem;
}
@media screen and (min-width: 64rem) {
  .pt-6 {
    padding-top: 1.5rem 0;
  }
}

.pt-7 {
  padding-top: 0.875rem;
}
@media screen and (min-width: 64rem) {
  .pt-7 {
    padding-top: 1.75rem;
  }
}

.pt-8 {
  padding-top: 1rem;
}
@media screen and (min-width: 64rem) {
  .pt-8 {
    padding-top: 2rem;
  }
}

.pt-9 {
  padding-top: 1.125rem;
}
@media screen and (min-width: 64rem) {
  .pt-9 {
    padding-top: 2.25rem;
  }
}

.pt-10 {
  padding-top: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .pt-10 {
    padding-top: 2.5rem;
  }
}

.pt-11 {
  padding-top: 1.375rem;
}
@media screen and (min-width: 64rem) {
  .pt-11 {
    padding-top: 2.75rem;
  }
}

.pt-12 {
  padding-top: 1.5rem;
}
@media screen and (min-width: 64rem) {
  .pt-12 {
    padding-top: 3rem;
  }
}

.pt-14 {
  padding-top: 1.75rem;
}
@media screen and (min-width: 64rem) {
  .pt-14 {
    padding-top: 3.5rem;
  }
}

.pt-16 {
  padding-top: 2rem;
}
@media screen and (min-width: 64rem) {
  .pt-16 {
    padding-top: 4rem;
  }
}

.pt-18 {
  padding-top: 2.25rem;
}
@media screen and (min-width: 64rem) {
  .pt-18 {
    padding-top: 4.5rem;
  }
}

.pt-20 {
  padding-top: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .pt-20 {
    padding-top: 5rem;
  }
}

.pt-36 {
  padding-top: 4.5rem;
}
@media screen and (min-width: 64rem) {
  .pt-36 {
    padding-top: 9rem;
  }
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.125rem;
}
@media screen and (min-width: 64rem) {
  .pb-1 {
    padding-bottom: 0.25rem;
  }
}

.pb-2 {
  padding-bottom: 0.25rem;
}
@media screen and (min-width: 64rem) {
  .pb-2 {
    padding-bottom: 0.5rem;
  }
}

.pb-3 {
  padding-bottom: 0.375rem;
}
@media screen and (min-width: 64rem) {
  .pb-3 {
    padding-bottom: 0.75rem;
  }
}

.pb-4 {
  padding-bottom: 0.5rem;
}
@media screen and (min-width: 64rem) {
  .pb-4 {
    padding-bottom: 1rem;
  }
}

.pb-5 {
  padding-bottom: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .pb-5 {
    padding-bottom: 1.25rem;
  }
}

.pb-6 {
  padding-bottom: 0.75rem;
}
@media screen and (min-width: 64rem) {
  .pb-6 {
    padding-bottom: 1.5rem;
  }
}

.pb-7 {
  padding-bottom: 0.875rem;
}
@media screen and (min-width: 64rem) {
  .pb-7 {
    padding-bottom: 1.75rem;
  }
}

.pb-8 {
  padding-bottom: 1rem;
}
@media screen and (min-width: 64rem) {
  .pb-8 {
    padding-bottom: 2rem;
  }
}

.pb-9 {
  padding-bottom: 1.125rem;
}
@media screen and (min-width: 64rem) {
  .pb-9 {
    padding-bottom: 2.25rem;
  }
}

.pb-10 {
  padding-bottom: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .pb-10 {
    padding-bottom: 2.5rem;
  }
}

.pb-11 {
  padding-bottom: 1.375rem;
}
@media screen and (min-width: 64rem) {
  .pb-11 {
    padding-bottom: 2.75rem;
  }
}

.pb-12 {
  padding-bottom: 1.5rem;
}
@media screen and (min-width: 64rem) {
  .pb-12 {
    padding-bottom: 3rem;
  }
}

.pb-14 {
  padding-bottom: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .pb-14 {
    padding-bottom: 3.5rem;
  }
}

.pb-16 {
  padding-bottom: 2rem;
}
@media screen and (min-width: 64rem) {
  .pb-16 {
    padding-bottom: 4rem;
  }
}

.pb-18 {
  padding-bottom: 2.25rem;
}
@media screen and (min-width: 64rem) {
  .pb-18 {
    padding-bottom: 4.5rem;
  }
}

.pb-20 {
  padding-bottom: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .pb-20 {
    padding-bottom: 5rem;
  }
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@media screen {
  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }

  html:not(.no-js) [data-aos=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  html:not(.no-js) [data-aos=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
  }

  html:not(.no-js) [data-aos=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
  }

  html:not(.no-js) [data-aos=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0);
  }

  html:not(.no-js) [data-aos=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0);
  }

  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
  }

  html:not(.no-js) [data-aos=zoom-in] {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
    transform: translate3d(0, 100px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
    transform: translate3d(0, -100px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
    transform: translate3d(100px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos=zoom-out] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    visibility: hidden;
  }

  html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  html:not(.no-js) [data-aos=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  html:not(.no-js) [data-aos=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  html:not(.no-js) [data-aos=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
  }

  html:not(.no-js) [data-aos=flip-left].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
  }

  html:not(.no-js) [data-aos=flip-right].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
  }

  html:not(.no-js) [data-aos=flip-up].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }

  html:not(.no-js) [data-aos=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
  }

  html:not(.no-js) [data-aos=flip-down].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}
.dir-listing {
  padding: 1.25rem;
  border: 1px solid #f1f5f9;
  border-radius: 0.5rem;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
@media (max-width: 47.9375rem) {
  body {
    font-size: 0.875rem;
  }
}
@media (min-width: 48rem) and (max-width: 63.9375rem) {
  body {
    font-size: 1rem;
  }
}
@media (min-width: 64rem) and (max-width: 79.9375rem) {
  body {
    font-size: 1.125rem;
  }
}
@media (min-width: 80rem) and (max-width: 95.9375rem) {
  body {
    font-size: 1.25rem;
  }
}
@media (min-width: 96rem) {
  body {
    font-size: 1.5rem;
  }
}

.main {
  margin-top: 4.5rem;
}

[x-cloak] {
  display: none !important;
}

@font-face {
  font-family: "Overpass";
  src: url("static/fonts/overpass/Overpass-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
}
@font-face {
  font-family: "Public Sans";
  src: url("static/fonts/public-sans/PublicSans-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
}
@font-face {
  font-family: "Caveat";
  src: url("static/fonts/caveat/Caveat-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
}
body {
  font-family: "Public Sans", sans-serif;
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  line-height: 1.33;
  color: #64748b;
}

h1,
h2,
.person__heading,
h3,
.people__heading,
.projects__heading,
._form-title,
h4,
.resources__heading,
.courses__heading,
h5,
.deal__heading,
.hero__subheading,
.section__subheading,
h6 {
  font-family: "Overpass", sans-serif;
  line-height: 1;
  color: #0f172a;
}

h1 {
  font-size: clamp(3.16rem, 2.6rem + 1.39vw, 3.95rem);
  font-weight: 900;
  letter-spacing: -0.05em;
}

h1 + p {
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  font-weight: 500;
  color: #94a3b8;
}

h2, .person__heading {
  font-size: clamp(2.37rem, 1.95rem + 1.04vw, 2.96rem);
  font-weight: 600;
  letter-spacing: -0.025em;
}

/* h2 + p {
  font-size: map-get($text-size, "400");
  font-weight: 400;
  color: map-get($slate, "400");
} */
h3, .people__heading, .projects__heading, ._form-title,
h4,
.resources__heading,
.courses__heading,
h5,
.deal__heading,
.hero__subheading,
.section__subheading,
h6 {
  font-weight: 700;
}

h3, .people__heading, .projects__heading, ._form-title {
  font-size: clamp(1.78rem, 1.46rem + 0.78vw, 2.22rem);
}

h4, .resources__heading, .courses__heading {
  font-size: clamp(1.33rem, 1.1rem + 0.59vw, 1.67rem);
}

h5, .deal__heading, .hero__subheading, .section__subheading {
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
}

h6 {
  font-size: clamp(0.75rem, 0.62rem + 0.33vw, 0.94rem);
}

p {
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  line-height: 1.33;
  font-weight: 400;
}

strong {
  font-weight: 700;
  color: #334155;
}

.prose, .prose--cos, .account__wrapper {
  width: 100%;
  max-width: 65ch;
}

.prose p, .prose--cos p, .account__wrapper p {
  margin-block: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
}
.prose p:first-of-type, .prose--cos p:first-of-type, .account__wrapper p:first-of-type {
  margin-top: 0;
}

.prose h2, .prose .person__heading, .prose--cos h2, .prose--cos .person__heading, .account__wrapper h2, .account__wrapper .person__heading {
  font-size: clamp(1.33rem, 1.1rem + 0.59vw, 1.67rem);
  margin-top: clamp(1.5rem, 0.8rem + 1.76vw, 2.5rem);
  margin-bottom: clamp(0.75rem, 0.4rem + 0.88vw, 1.25rem);
}

.prose h3, .prose .people__heading, .prose .projects__heading, .prose--cos h3, .prose--cos .people__heading, .prose--cos .projects__heading, .account__wrapper h3, .account__wrapper .people__heading, .account__wrapper .projects__heading, .prose ._form-title, .prose--cos ._form-title, .account__wrapper ._form-title {
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  margin-top: clamp(1rem, 0.38rem + 1.54vw, 1.88rem);
  margin-bottom: clamp(0.5rem, 0.19rem + 0.77vw, 0.94rem);
}

.prose h4, .prose .resources__heading, .prose .courses__heading, .prose--cos h4, .prose--cos .resources__heading, .prose--cos .courses__heading, .account__wrapper h4, .account__wrapper .resources__heading, .account__wrapper .courses__heading,
.prose h5,
.prose .deal__heading,
.prose .hero__subheading,
.prose .section__subheading,
.prose--cos h5,
.prose--cos .deal__heading,
.prose--cos .hero__subheading,
.prose--cos .section__subheading,
.account__wrapper h5,
.account__wrapper .deal__heading,
.account__wrapper .hero__subheading,
.account__wrapper .section__subheading,
.prose h6,
.prose--cos h6,
.account__wrapper h6 {
  margin-top: clamp(0.75rem, 0.4rem + 0.88vw, 1.25rem);
  margin-bottom: clamp(0.25rem, -0.01rem + 0.66vw, 0.63rem);
}

.prose h4, .prose .resources__heading, .prose .courses__heading, .prose--cos h4, .prose--cos .resources__heading, .prose--cos .courses__heading, .account__wrapper h4, .account__wrapper .resources__heading, .account__wrapper .courses__heading {
  font-size: clamp(0.75rem, 0.62rem + 0.33vw, 0.94rem);
}

.prose h5, .prose .deal__heading, .prose .hero__subheading, .prose .section__subheading, .prose--cos h5, .prose--cos .deal__heading, .prose--cos .hero__subheading, .prose--cos .section__subheading, .account__wrapper h5, .account__wrapper .deal__heading, .account__wrapper .hero__subheading, .account__wrapper .section__subheading,
.prose h6,
.prose--cos h6,
.account__wrapper h6 {
  font-size: clamp(0.56rem, 0.46rem + 0.25vw, 0.7rem);
}

.prose figure, .prose--cos figure, .account__wrapper figure {
  margin-block: clamp(2rem, 1.65rem + 0.88vw, 2.5rem);
}

.prose img, .prose--cos img, .account__wrapper img {
  width: 100%;
  height: auto;
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.prose a, .prose--cos a, .account__wrapper a {
  font-weight: 600;
  color: #ef3d43;
  text-decoration: none;
}

.prose figcaption, .prose--cos figcaption, .account__wrapper figcaption {
  font-size: 1rem;
  line-height: 1.33rem;
  text-align: center;
}

.prose ol, .prose--cos ol, .account__wrapper ol {
  list-style: decimal inside;
}

.prose ul > li, .prose--cos ul > li, .account__wrapper ul > li {
  position: relative;
  margin-block: 1.25rem;
  padding-left: 2.5rem;
  font-size: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  line-height: 1.33rem;
}
.prose ul > li::before, .prose--cos ul > li::before, .account__wrapper ul > li::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  z-index: initial;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("static/img/leaf-filled.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.prose blockquote, .prose--cos blockquote, .account__wrapper blockquote {
  border-left: 10px solid #ef3d43;
  margin-block: 2.5rem;
  padding-left: 2.5rem;
  color: #64748b;
}
.prose blockquote > cite, .prose--cos blockquote > cite, .account__wrapper blockquote > cite {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  font-style: normal;
}

.btn, .gform_save_link, .person__btn, .gform_button,
.gform_next_button, .nav__btn, ._submit--hello, ._submit, input[type=submit], .wp-block-button__link {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  padding-inline: clamp(1.5rem, 1.24rem + 0.66vw, 1.88rem);
  width: 100%;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  white-space: no-wrap;
  cursor: pointer;
}
@media screen and (min-width: 64rem) {
  .btn, .gform_save_link, .person__btn, .gform_button,
.gform_next_button, .nav__btn, ._submit--hello, ._submit, input[type=submit], .wp-block-button__link {
    width: auto;
  }
}
.btn:hover, .gform_save_link:hover, .person__btn:hover, .gform_button:hover,
.gform_next_button:hover, .nav__btn:hover, ._submit--hello:hover, ._submit:hover, input[type=submit]:hover, .wp-block-button__link:hover {
  transform: translateY(-2px);
}
.btn--primary, .gform_button,
.gform_next_button, .nav__btn, ._submit--hello, ._submit, input[type=submit], .wp-block-button__link {
  color: white !important;
  background-color: #ef3d43;
  border: 1px solid #ef3d43;
}
.btn--secondary, .gform_save_link, .person__btn {
  color: #ef3d43;
  background-color: #ffffff;
  border: 1px solid #ef3d43;
}

.glider--logo {
  width: auto;
  height: min(100%, 2.75rem);
}

.container, .nav__container, .hello__container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 39.9375rem) {
  .container, .nav__container, .hello__container {
    padding: 0 1rem;
  }
}
@media screen and (min-width: 40rem) and (max-width: 47.9375rem) {
  .container, .nav__container, .hello__container {
    max-width: 40rem;
    padding: 0 2rem;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  .container, .nav__container, .hello__container {
    max-width: 48rem;
    padding: 0 3rem;
  }
}
@media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
  .container, .nav__container, .hello__container {
    max-width: 64rem;
    padding: 0 4rem;
  }
}
@media screen and (min-width: 80rem) and (max-width: 95.9375rem) {
  .container, .nav__container, .hello__container {
    max-width: 80rem;
    padding: 0 5rem;
  }
}
@media screen and (min-width: 96rem) {
  .container, .nav__container, .hello__container {
    max-width: 96rem;
    padding: 0 6rem;
  }
}

input,
textarea {
  height: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1;
  min-height: 2.75rem;
}
@media screen and (min-width: 64rem) {
  input,
textarea {
    min-height: 3.5rem;
  }
}

textarea {
  resize: none;
}

input[type=checkbox] {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
}

input[type=text],
textarea {
  border: 1px solid #f1f5f9;
}

input[type=submit] {
  cursor: pointer;
}

.af-form-wrapper {
  width: 100%;
  max-width: 41.875rem;
  margin: 0 auto;
}

._form {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
}

._form_3,
._form_5,
._form_7 {
  padding: 2.5rem 1.875rem;
  border: 1px solid #f1f5f9;
}
@media screen and (min-width: 64rem) {
  ._form_3,
._form_5,
._form_7 {
    padding: 5rem 3.75rem;
  }
}

._form-title {
  font-weight: 700;
  color: #334155;
}

._form p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #94a3b8;
}

._form_element._x54192622,
._form_element._x71006041,
._form_element._x53478544,
._form_element._x74202095 {
  margin: 1.25rem 0;
}

._field-wrapper {
  margin: 1.25rem 0;
}

._field-wrapper,
input {
  width: 100%;
  background-color: #f8fafc;
  border-radius: 0.25rem;
  border: unset;
}

._submit {
  margin-top: 2.5rem;
}

.tribe-linked-type-venue-phone,
.tribe-linked-type-venue-website {
  display: none;
}

.mp-form-label label {
  visibility: hidden;
}

#mepr_loginform input[type=submit] {
  max-width: unset;
}
#mepr_loginform label {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input[type=checkbox] {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  margin-right: 1rem;
}

.mepr-login-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
}

.gform_ajax_spinner {
  display: none !important;
}

#wishlist-member-profile-form input[type=text],
#wishlist-member-profile-form input[type=email] {
  background-color: #ffffff;
}

.wlm3-form > p {
  text-align: start !important;
}

.wlm3-form-group button[type=button] {
  border: none !important;
  color: #ef3d43 !important;
  text-decoration: underline !important;
}

#hello {
  position: fixed;
  right: 1.25%;
  bottom: 1.25%;
  left: 1.25%;
  z-index: 900;
  display: grid;
  place-items: center;
  margin-inline: auto;
  padding: 2.5rem;
  width: auto;
  background-color: #1c3252;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 64rem) {
  #hello {
    padding: 1.25rem 0;
  }
}
#hello::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 8rem;
  width: 8rem;
  background-image: url("static/img/clipboard-list.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transform: translate(0, -50%) rotate(5deg);
  opacity: 0.2;
}

.hello__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 64rem) {
  .hello__container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.hello__left {
  margin-bottom: clamp(2rem, 1.65rem + 0.88vw, 2.5rem);
  text-align: center;
}
@media screen and (min-width: 64rem) {
  .hello__left {
    margin-bottom: unset;
    text-align: start;
  }
}

.hello__heading {
  font-weight: 700;
  color: #ffffff;
}

.hello__subheading {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #94a3b8;
}

.hello__right {
  width: 100%;
}
@media screen and (min-width: 64rem) {
  .hello__right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
}

#_form_9_ {
  width: 100%;
  box-shadow: unset;
  border-radius: unset;
}

._form-content--hello {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  row-gap: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  width: 100%;
}
@media screen and (min-width: 64rem) {
  ._form-content--hello {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    column-gap: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
    row-gap: unset;
  }
}

._form_element--hello {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 64rem) {
  ._form_element--hello {
    margin-bottom: unset;
    grid-column: span 3/span 3;
  }
}

._field-wrapper--hello > input {
  font-size: 1rem;
  line-height: 1.5rem;
}
@media screen and (min-width: 64rem) {
  ._field-wrapper--hello > input {
    height: 100%;
  }
}

._button-wrapper._full_width {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 64rem) {
  ._button-wrapper._full_width {
    grid-column: span 4/span 4;
  }
}

._submit--hello {
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

._form-label {
  display: none;
}

.hello__close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: centerl;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}
@media screen and (min-width: 64rem) {
  .hello__close-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1.75rem;
    height: 100%;
  }
}
.hello__close-wrapper:hover {
  opacity: 1;
}

.hello__close-icon {
  width: auto;
  height: 1.75rem;
}

.section {
  position: relative;
}
.section--full {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}

.toggle-wrapper {
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.5rem;
}

.toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 4rem;
  height: 2rem;
  margin: 0 1rem;
  padding: 0.25rem;
  background-color: #f1f5f9;
  border-radius: 100vw;
  cursor: pointer;
  transform: translateX(0);
  transition: background-color 0.3s ease-in-out;
}
.toggle.is-red {
  background-color: #ef3d43;
}

.toggle__flag {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}
.toggle__flag.active {
  color: #ef3d43;
}

.toggle__btn {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  background-color: #ffffff;
  border-radius: 100vw;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.toggle__btn.is-clicked {
  transform: translateX(100%);
}

#footer {
  background-color: #1c3252;
}

.footer__wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, min-content));
  gap: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .footer__wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.footer__heading {
  margin-bottom: 1.25rem;
  font-weight: 700;
  color: #ffffff;
}

.footer__link {
  margin-bottom: 0.625rem;
  font-size: 1rem;
  line-height: 1.33rem;
  font-weight: 600;
  color: #64748b;
  transition: color 0.3s ease-in-out;
}
.footer__link:hover {
  color: #ffffff;
}

.footer__col, .footer__col--small {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer__col--small {
  margin-bottom: 1.25rem;
}

.footer__bottom {
  border-top: 1px solid #94a3b8;
}

.footer__bottom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer__copyright {
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

.nav {
  display: grid;
  place-items: center;
  width: 100%;
  height: 4.5rem;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}
.nav.is-scrolled {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}

.nav__container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__menu {
  display: none;
}
@media screen and (min-width: 64rem) {
  .nav__menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.nav__logo, .logo, .footer__logo {
  width: auto;
  height: 2.25rem;
}

.nav__link-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.nav__link, .dropdown__item, .nav__link--membership {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  font-size: clamp(0.75rem, 0.62rem + 0.33vw, 0.94rem);
  line-height: 1.33rem;
  font-weight: 600;
  color: #64748b;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}
.nav__link:hover, .dropdown__item:hover, .nav__link--membership:hover {
  background-color: #f8fafc;
}
.nav__link--membership {
  color: #ef3d43;
}

.nav__right {
  display: none;
}
@media screen and (min-width: 64rem) {
  .nav__right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.nav__btn {
  width: unset;
  max-width: unset;
  min-height: 1.75rem;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown__menu {
  position: absolute;
  top: calc(100% + 1.25rem);
  right: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  width: max-content;
  padding: 1rem;
  border-radius: 0.75rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border: 2px solid #f1f5f9;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.dropdown__item {
  margin: unset;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropdown__item__icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}

.dropdown__teaser {
  font-size: 0.875rem;
  line-height: 1;
  color: #94a3b8;
}

.dropdown__link {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.dropdown__icon {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  margin-top: -0.25rem;
}

.nav__hamburger,
.nav__mobile-close {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav__hamburger {
  border: 1px solid #f1f5f9;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: border-color 0.3s;
}
.nav__hamburger:hover {
  border-color: #ef3d43;
}
@media screen and (min-width: 64rem) {
  .nav__hamburger {
    display: none;
  }
}

.nav__logo--hamburger {
  width: auto;
  height: 1.5rem;
}

.nav__mobile-menu {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px) saturate(110%);
}

.nav__mobile-close {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.nav__mobile-close-icon {
  width: auto;
  height: 1.5rem;
}

.nav__link--mobile {
  display: block;
  margin-block: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
  font-size: clamp(2.37rem, 1.95rem + 1.04vw, 2.96rem);
  text-align: center;
  transition: color 0.3s;
}
.nav__link--mobile:hover {
  color: #ef3d43;
}

.dropdown__item--mobile {
  display: block;
  text-align: center;
}
.dropdown__item--mobile:not(:last-of-type) {
  margin-bottom: clamp(1rem, 0.82rem + 0.44vw, 1.25rem);
}

.my-events-header {
  display: none;
}

.error__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 65ch;
  text-align: center;
}

.error__heading {
  font-weight: 900;
  letter-spacing: -0.05em;
}
@media screen and (max-width: 40rem) {
  .error__heading {
    font-size: 7.125rem;
    line-height: 1;
  }
}
@media screen and (min-width: 40rem) and (max-width: 47.9375rem) {
  .error__heading {
    font-size: 7.125rem;
    line-height: 1;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  .error__heading {
    font-size: 9.375em;
    line-height: 1;
  }
}
@media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
  .error__heading {
    font-size: 9.375rem;
    line-height: 1;
  }
}
@media screen and (min-width: 80rem) and (max-width: 95.9375rem) {
  .error__heading {
    font-size: 12.75rem;
    line-height: 1;
  }
}
@media screen and (min-width: 96rem) {
  .error__heading {
    font-size: 12.75rem;
    line-height: 1;
  }
}

.account__card {
  padding: 2.5rem;
  background-color: #f8fafc;
  border: 2px solid #f1f5f9;
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: 1.33rem;
  font-weight: 400;
}
.account__card:not(last-of-type) {
  margin-bottom: 1.25rem;
}
.account__card h3, .account__card .people__heading, .account__card .projects__heading, .account__card ._form-title {
  margin-top: 0;
}
.account__card ul > li {
  padding-left: unset;
}
.account__card ul > li::before {
  display: none;
}
.account__card a {
  display: block;
  margin: 1.25rem 0;
}
.account__card i,
.account__card br {
  display: none;
}
.account__card .wishlistmember-mergecode-payperposts-showmorebutton {
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 64rem) {
  .account__card:not(last-of-type) {
    margin-bottom: 2.5rem;
  }
}

#hero--page {
  background-color: #f8fafc;
  /*   background-image: url("static/img/cos-hero.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
}
@media screen and (max-width: 39.9375rem) {
  #hero--page {
    padding-top: 3.75rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 40rem) and (max-width: 47.9375rem) {
  #hero--page {
    padding-top: 5rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 7.5rem;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  #hero--page {
    padding-top: 5rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8.75rem;
  }
}
@media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
  #hero--page {
    padding-top: 7.5rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12.5rem;
  }
}
@media screen and (min-width: 80rem) and (max-width: 95.9375rem) {
  #hero--page {
    padding-top: 8.75rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13.75rem;
  }
}
@media screen and (min-width: 96rem) {
  #hero--page {
    padding-top: 10rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15rem;
  }
}
#hero--page::after {
  content: "";
  position: absolute;
  z-index: 100;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url("static/img/border-jagged2.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 63.9375rem) {
  #hero--page::after {
    height: 2.5rem;
  }
}
@media screen and (min-width: 64rem) {
  #hero--page::after {
    height: 5rem;
  }
}

.hero__heading--page {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .hero__heading--page {
    margin-bottom: 1.25rem;
  }
}

.prose--cos a {
  color: #ed5424;
}

.course__wrapper {
  display: grid;
  grid-template-rows: repeat(7, minmax(0, min-content));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: flex-start;
  grid-gap: 1.875rem;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .course__wrapper {
    grid-template-rows: repeat(3, minmax(0, min-content));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.course__card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem;
  border: 2px solid #f1f5f9;
  border-radius: 0.75rem;
  transition: all 0.3s ease-in-out;
}
.course__card:hover {
  transform: translateY(-4px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-color: #ef3d43;
}

.course__card__thumbnail {
  width: 100%;
  height: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.hero__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, min-content));
  gap: 3.75rem;
}
@media screen and (min-width: 64rem) {
  .hero__grid {
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.hero__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (min-width: 64rem) {
  .hero__left {
    grid-column: span 5/span 5;
  }
}

.hero__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30rem;
}
@media screen and (min-width: 64rem) {
  .hero__right {
    grid-column: span 7/span 7;
  }
}

.hero__right__img {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  background-image: url("static/img/occ-hero.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  outline: 12px solid #ffffff;
}
@media screen and (min-width: 64rem) {
  .hero__right__img {
    transform: rotate(0.75deg);
  }
}

.hero__right__copy {
  position: relative;
  margin-top: 3.75rem;
  font-family: "Caveat", sans-serif;
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 500;
  color: #ef3d43;
  text-align: center;
}
.hero__right__copy::after {
  content: "";
  position: absolute;
  top: -7.5rem;
  right: -10rem;
  width: 10rem;
  height: 10rem;
  background-image: url("static/img/arrow-vector.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.hero__wrapper,
.intro__wrapper {
  width: 100%;
  max-width: 65ch;
  margin: 0 auto;
  text-align: center;
}

.hero__heading, .our-people__heading {
  font-weight: 900;
}

.hero__subheading, .section__subheading {
  font-weight: 500;
  color: #94a3b8;
}

.hero__subheading + a, .section__subheading + a,
.hero__subheading + p,
.section__subheading + p {
  margin-top: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .hero__subheading + a, .section__subheading + a,
.hero__subheading + p,
.section__subheading + p {
    margin-top: 1.25rem;
  }
}

.hero__play-btn {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-top: -2.5rem;
  margin-right: -2.5rem;
  width: 5rem;
  height: 5rem;
  border: none;
  border-radius: 100vw;
  box-shadow: 0 0 0 0 rgba(239, 61, 67, 0.5);
  animation: pulse 1.5s infinite;
  background-image: url("static/img/play.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.hero__modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(16px);
}

.hero__modal__content {
  width: 75%;
  max-width: 67.5rem;
  text-align: center;
}

.hero__video-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  margin-bottom: 1.25rem;
  outline: 12px solid #ffffff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  transform: rotate(0.625deg);
}

.hero__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__modal__close {
  border: unset;
  color: #ffffff;
  opacity: 0.5;
  text-decoration: underline;
  transition: opacity 0.3s ease-in-out;
}
.hero__modal__close:hover {
  opacity: 1;
}

.resources__wrapper, .courses__wrapper {
  display: grid;
  grid-gap: 1.875rem;
}
@media screen and (max-width: 63.9375rem) {
  .resources__wrapper, .courses__wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .resources__wrapper, .courses__wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    margin-top: 5rem;
  }
}

.resources__card, .courses__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1.25rem;
  text-align: center;
  border: 1px solid #f1f5f9;
  border-radius: 0.5rem;
}

.resources__img {
  width: auto;
  height: 10rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .resources__img {
    margin-bottom: 2.5rem;
  }
}

.resources__heading, .courses__heading {
  font-weight: 700;
  margin-bottom: 0.625rem;
}

.resources__summary, .courses__summary {
  font-size: 1rem;
  line-height: 1.33;
  color: #94a3b8;
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 64rem) {
  .resources__summary, .courses__summary {
    margin-bottom: 2.5rem;
  }
}

.download-icon {
  width: 1rem;
  height: 1rem;
  background-image: url("static/img/download.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
@media screen and (max-width: 63.9375rem) {
  .download-icon {
    margin-right: 0.25rem;
  }
}
@media screen and (min-width: 64rem) {
  .download-icon {
    margin-right: 0.5rem;
  }
}

#projects {
  background-color: #f3f8fa;
}
#projects::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  background-image: url("static/img/border-jagged.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 63.9375rem) {
  #projects::before {
    top: 0rem;
    height: 2.5rem;
  }
}
@media screen and (min-width: 64rem) {
  #projects::before {
    top: 0rem;
    height: 5rem;
  }
}
#projects::after {
  content: "";
  position: absolute;
  z-index: 50;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20rem;
  background-image: url("static/img/trees.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.section__heading {
  margin-bottom: 0.625rem;
}
@media screen and (min-width: 64rem) {
  .section__heading {
    margin-bottom: 1.25rem;
  }
}

.projects__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects__card {
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 60.625rem;
  margin: 0 auto;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  background-image: url("static/img/field-leader.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 63.9375rem) {
  .projects__card {
    padding: 2.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .projects__card {
    padding: 5rem;
  }
}

.projects__card__wrapper {
  width: 100%;
}

@media screen and (min-width: 64rem) {
  .projects__left {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.projects__heading {
  font-weight: 700;
  color: #ffffff;
}
@media screen and (max-width: 63.9375rem) {
  .projects__heading {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 64rem) {
  .projects__heading {
    margin-bottom: 1.25rem;
  }
}

.projects__subheading {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 63.9375rem) {
  .projects__subheading {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 64rem) {
  .projects__subheading {
    margin-bottom: 2.5rem;
  }
}

.projects__tabs-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  border-radius: 0.25rem;
  background-color: rgba(88, 112, 123, 0.1);
  margin: 0 auto 1.25rem auto;
  padding: 0.5rem;
}
@media screen and (min-width: 64rem) {
  .projects__tabs-wrapper {
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.5rem;
    width: auto;
  }
}

.projects__tab {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: all 0.3s ease-in-out;
  color: #64748b;
  text-align: center;
}
.projects__tab:hover {
  background-color: #cbd5e1;
}
.projects__tab.active {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.section__helmet--projects {
  margin-bottom: 1.25rem;
}

.about__wrapper {
  display: grid;
  grid-gap: 3.75rem;
}
@media screen and (max-width: 63.9375rem) {
  .about__wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, min-content));
    margin-top: 2.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .about__wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    margin-top: 5rem;
  }
}

.about__left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 64rem) {
  .about__left {
    grid-column: span 2/span 2;
  }
}

.about__testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ef3d43;
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: 400;
  color: #ffffff;
}
@media screen and (max-width: 63.9375rem) {
  .about__testimonial {
    padding: 2.5rem 1.25rem;
  }
}
@media screen and (min-width: 64rem) {
  .about__testimonial {
    padding: 5rem 2.5rem;
  }
}

@media screen and (min-width: 64rem) {
  .about__right {
    grid-column: span 3/span 3;
  }
}

.testimonial__author {
  font-weight: 700;
}

.testimonial__copy {
  margin-bottom: 1.25rem;
}

#courses {
  background-color: #f8fafc;
}
@media screen and (max-width: 63.9375rem) {
  #courses {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 64rem) {
  #courses {
    margin-top: 10rem;
  }
}
#courses::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  background-image: url("static/img/mountains.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 63.9375rem) {
  #courses::before {
    top: -5rem;
    height: 5rem;
  }
}
@media screen and (min-width: 64rem) {
  #courses::before {
    top: -10rem;
    height: 10rem;
  }
}

.member__card {
  width: 100%;
  max-width: 60.625rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  padding: 2.5rem 1.25rem;
  background-image: url("static/img/member.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (min-width: 64rem) {
  .member__card {
    padding: 5rem 2.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.member__content {
  width: 100%;
  max-width: 35.625rem;
  text-align: center;
}

.member__heading {
  color: #ffffff;
}
@media screen and (max-width: 63.9375rem) {
  .member__heading {
    margin-bottom: 0.625rem;
  }
}
@media screen and (min-width: 64rem) {
  .member__heading {
    margin-bottom: 1.25rem;
  }
}

.member__subheading {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (max-width: 63.9375rem) {
  .member__subheading {
    margin-bottom: 1.25rem;
  }
}
@media screen and (min-width: 64rem) {
  .member__subheading {
    margin-bottom: 2.5rem;
  }
}

#contact {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
@media screen and (max-width: 39.9375rem) {
  #contact {
    padding-top: 2.5rem;
  }
}
@media screen and (min-width: 40rem) and (max-width: 47.9375rem) {
  #contact {
    padding-top: 3.75rem;
  }
}
@media screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  #contact {
    padding-top: 5rem;
  }
}
@media screen and (min-width: 64rem) and (max-width: 79.9375rem) {
  #contact {
    padding-top: 6.25rem;
  }
}
@media screen and (min-width: 80rem) and (max-width: 95.9375rem) {
  #contact {
    padding-top: 7.5rem;
  }
}
@media screen and (min-width: 96rem) {
  #contact {
    padding-top: 8.75rem;
  }
}

#gform_19 {
  width: min(100%, 48.125rem);
  margin-inline: auto;
}

.gform_heading {
  display: none;
}

#input_19_4 {
  width: 100%;
  background-color: #f8fafc;
  border-radius: 0.25rem;
}

#gform_submit_button_19 {
  width: 100%;
}

.contact__email-wrapper {
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 63.9375rem) {
  .contact__email-wrapper {
    margin-top: 1.25rem;
  }
}
@media screen and (min-width: 64rem) {
  .contact__email-wrapper {
    margin-top: 2.5rem;
  }
}

.contact__email {
  color: #ef3d43;
}

@media screen and (max-width: 63.9375rem) {
  #mountains {
    padding-top: 15rem;
    padding-bottom: 7.5rem;
  }
}
@media screen and (min-width: 64rem) {
  #mountains {
    padding-top: 30rem;
    padding-bottom: 15rem;
  }
}
#mountains::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20rem;
  height: 20rem;
  background-image: url("static/img/birds.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
#mountains::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("static/img/mountain-range.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  /*     @media screen and (max-width: (map-get($breakpoints, "large") - 0.0625rem)) {
    height: 15rem;
  }

  @media screen and (min-width: map-get($breakpoints, "large")) {
    height: 30rem;
  } */
}

.carousel {
  width: 350rem;
  height: auto;
  animation: horizontalScroll 60s linear infinite;
}

.carousel__wrapper {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  display: grid;
  place-items: center;
  width: 17.5rem;
  height: 5rem;
}

.carousel__slide > img {
  width: auto;
  opacity: 0.5;
}

.carousel__logo--expert-voice,
.carousel__logo--cwf,
.carousel__logo--zoppinh {
  height: 2.5rem;
}

.carousel__logo--aeq,
.carousel__logo--joelette,
.carousel__logo--hikens,
.carousel__logo--viristar,
.carousel__logo--black-diamond {
  height: 3rem;
}

.carousel__logo--iga {
  height: 3.5rem;
}

.carousel__logo--ucalgary {
  height: 3.75rem;
}

.gfield-choice-input {
  width: 1.125rem;
  height: 1.125rem;
}

#input_2_4_6,
#input_2_11,
.gfield_select {
  width: 100%;
  height: 4rem;
  padding: 0.5rem 1rem;
  background-color: #f8fafc;
  border-radius: 0.25rem;
  border: unset;
}

#input_2_5 {
  width: 100%;
}

.section__helmet, .section__helmet--person, .section__helmet--projects {
  width: 100%;
  max-width: 65ch;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  text-align: center;
}
@media screen and (min-width: 64rem) {
  .section__helmet, .section__helmet--person, .section__helmet--projects {
    margin-bottom: 2.5rem;
  }
}

.badge {
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ef3d43;
  text-transform: uppercase;
  background-color: rgba(239, 61, 67, 0.05);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1.25rem;
}

#login {
  background-image: url("static/img/occ-login.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#login > * {
  position: relative;
  z-index: 100;
}
#login::after {
  content: "";
  position: absolute;
  z-index: 50;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 50, 82, 0.2);
  mix-blend-mode: multiply;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 29.375rem;
  margin: 0 auto 1.25rem auto;
  border: 1px solid #f1f5f9;
  border-radius: 0.75rem;
  background-color: #ffffff;
  padding: 2.5rem;
}

.login__heading {
  font-weight: 700;
  text-align: center;
}

.logo {
  margin-bottom: 2.5rem;
}

#back {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.login__bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login__bottom__heading {
  font-weight: 700;
  color: #ffffff;
}

.login__bottom__link {
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  color: #f1f5f9;
}

.wlm3-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.wlm3-form > p {
  text-align: center;
}

#wlm_form_field_wp-submit {
  margin: 0 auto 1.25rem auto;
  max-width: unset;
}

.wlm3-form .wlm3-form-group label:last-of-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.membership__wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 1.875rem;
  margin: 2.5rem 0;
}
@media screen and (min-width: 64rem) {
  .membership__wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
}

.membership__card, .deal__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  border: 2px solid #f1f5f9;
  border-radius: 0.75rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.membership__card:hover, .deal__card:hover {
  transform: translateY(-4px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-color: #ef3d43;
}
.membership__card:hover .membership__heading, .deal__card:hover .membership__heading {
  color: #ef3d43;
}

.membership__heading {
  margin-bottom: 1.25rem;
  transition: color 0.3s ease-in-out;
}

.membership__description {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #94a3b8;
  margin-bottom: 2.5rem;
}

#gform_7 fieldset:not(:last-of-type) {
  margin-bottom: clamp(0.75rem, 0.62rem + 0.33vw, 0.94rem);
}

.gform_previous_button {
  display: none !important;
}

#hero--page {
  background-image: url("static/img/banff-hero.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
#hero--page::before {
  content: "";
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(28, 50, 82, 0.4);
}
#hero--page > * {
  position: relative;
  z-index: 100;
}
#hero--page h1 {
  color: #ffffff;
}

.content__leaf {
  position: absolute;
  width: 15rem;
  height: auto;
  left: -2.5rem;
  top: 10rem;
  opacity: 0.5;
  visibility: hidden;
}
@media screen and (min-width: 64rem) {
  .content__leaf {
    visibility: visible;
  }
}

.content__moose {
  position: absolute;
  width: 12rem;
  height: auto;
  right: -1rem;
  top: 50%;
  visibility: hidden;
}
@media screen and (min-width: 64rem) {
  .content__moose {
    visibility: visible;
  }
}

.our-people__heading {
  margin-bottom: unset;
}

.people__heading {
  margin-bottom: 1.25rem;
}

.wrapper--staff,
.wrapper--board,
.wrapper--committee {
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 64rem) {
  .wrapper--staff,
.wrapper--board,
.wrapper--committee {
    margin-bottom: 5rem;
  }
}

.people__wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(auto, minmax(0, 1fr));
  gap: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .people__wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(auto, minmax(0, 1fr));
  }
}

.people__card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.25rem;
  border: 2px solid #f1f5f9;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.people__card:hover {
  transform: translateY(-4px);
  border-color: #ef3d43;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.people__card:hover .people__card__heading {
  color: #ef3d43;
}

.people__card__image, .person__thumbnail {
  width: 3.75rem;
  height: 3.75rem;
  border: 4px solid #ffffff;
  border-radius: 100vw;
  outline: 1px solid #ef3d43;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  margin-right: 1.25rem;
}

.people__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.people__card__heading {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 500;
}

.people__card__title {
  font-size: 1rem;
  line-height: 1;
  color: #cbd5e1;
}

.content__wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 65ch;
}

.person__thumbnail {
  margin-bottom: 1.25rem;
}

.person__position {
  font-weight: 500;
  color: #ef3d43;
}

.person__btn {
  margin-top: 2.5rem;
}

.person__info-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.person__email,
.person__bullet {
  font-weight: 500;
  color: #94a3b8;
}

.person__bullet {
  margin: 0 0.5rem;
}

.section__helmet--person {
  text-align: start;
}

.deal__wrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(auto, minmax(0, 1fr));
  gap: 1.875rem;
}
@media screen and (min-width: 64rem) {
  .deal__wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(auto, minmax(0, 1fr));
  }
}

.deal__logo {
  width: auto;
  height: 2rem;
  opacity: 0.5;
}

.deal__heading {
  font-weight: 700;
}

.deal__excerpt {
  font-size: 1rem;
  line-height: 1.33rem;
  color: #94a3b8;
}

.resource__download {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 29.375rem;
  margin-top: 3.75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem;
  border-radius: 0.75rem;
  background-color: #f7efd5;
  border: 3px dashed #92782e;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  overflow: none;
  color: #92782e;
  transition: all 0.3s ease-in-out;
}
.resource__download:hover {
  transform: translateY(-4px);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.resource__download__copy {
  position: relative;
  padding-right: 2.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  text-decoration: underline;
}
.resource__download__copy::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.5rem;
  background-image: url("static/img/external-link.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}