// ********************
// HELLO BAR
// ********************

#hello {
  position: fixed;
  right: 1.25%;
  bottom: 1.25%;
  left: 1.25%;
  z-index: 900;
  display: grid;
  place-items: center;
  margin-inline: auto;
  padding: 2.5rem;
  width: auto;
  background-color: $cello;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba($black, 0.1),
    0 4px 6px -4px rgba($black, 0.1);

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 1.25rem 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 8rem;
    width: 8rem;
    background-image: url("static/img/clipboard-list.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform: translate(0, -50%) rotate(5deg);
    opacity: 0.2;
  }
}

.hello__container {
  @extend .container;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.hello__left {
  margin-bottom: map-get($spacing, "600");
  text-align: center;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: unset;
    text-align: start;
  }
}

.hello__heading {
  font-weight: 700;
  color: $white;
}

.hello__subheading {
  font-size: 1rem;
  line-height: 1.5rem;
  color: map-get($slate, "400");
}

// ********************
// QUIZ FORM
// ********************

.hello__right {
  width: 100%;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
  }
}

#_form_9_ {
  width: 100%;
  box-shadow: unset;
  border-radius: unset;
}

._form-content--hello {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  row-gap: map-get($spacing, "400");
  width: 100%;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    column-gap: map-get($spacing, "400");
    row-gap: unset;
  }
}

._form_element--hello {
  width: 100%;
  height: 100%;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: unset;
    grid-column: span 3 / span 3;
  }
}

._field-wrapper--hello > input {
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    height: 100%;
  }
}

._button-wrapper._full_width {
  width: 100%;
  height: 100%;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    grid-column: span 4 / span 4;
  }
}

._submit--hello {
  @extend .btn;
  @extend .btn--primary;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

._form-label {
  display: none;
}

.hello__close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.25rem;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: centerl;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 1.75rem;
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
}

.hello__close-icon {
  width: auto;
  height: 1.75rem;
}
