// ********************
// MARGIN X
// ********************

.mx-auto {
  margin: 0 auto;
}

// ********************
// MARGIN Y
// ********************

.my-0 {
  margin: 0 0;
}

.my-1 {
  margin: 0.125rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 0.25rem 0;
  }
}

.my-2 {
  margin: 0.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 0.5rem 0;
  }
}

.my-3 {
  margin: 0.375rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 0.75rem 0;
  }
}

.my-4 {
  margin: 0.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 1rem 0;
  }
}

.my-5 {
  margin: 0.625rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 1.25rem 0;
  }
}

.my-6 {
  margin: 0.75rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 1.5rem 0;
  }
}

.my-7 {
  margin: 0.875rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 1.75rem 0;
  }
}

.my-8 {
  margin: 1rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 2rem 0;
  }
}

.my-9 {
  margin: 1.125rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 2.25rem 0;
  }
}

.my-10 {
  margin: 1.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 2.5rem 0;
  }
}

.my-11 {
  margin: 1.375rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 2.75rem 0;
  }
}

.my-12 {
  margin: 1.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 3rem 0;
  }
}

.my-14 {
  margin: 1.75rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 3.5rem 0;
  }
}

.my-16 {
  margin: 2rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 4rem 0;
  }
}

.my-18 {
  margin: 2.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 4.5rem 0;
  }
}

.my-20 {
  margin: 2.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin: 5rem 0;
  }
}

// ********************
// MARGIN TOP
// ********************

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 0.25rem;
  }
}

.mt-2 {
  margin-top: 0.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 0.5rem;
  }
}

.mt-3 {
  margin-top: 0.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 0.75rem;
  }
}

.mt-4 {
  margin-top: 0.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 1rem;
  }
}

.mt-5 {
  margin-top: 0.625rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 1.25rem;
  }
}

.mt-6 {
  margin-top: 0.75rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 1.5rem;
  }
}

.mt-7 {
  margin-top: 0.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 1.75rem;
  }
}

.mt-8 {
  margin-top: 1rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 2rem;
  }
}

.mt-9 {
  margin-top: 1.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 2.25rem;
  }
}

.mt-10 {
  margin-top: 1.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 2.5rem;
  }
}

.mt-11 {
  margin-top: 1.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 2.75rem;
  }
}

.mt-12 {
  margin-top: 1.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 3rem;
  }
}

.mt-14 {
  margin-top: 1.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 3.5rem;
  }
}

.mt-16 {
  margin-top: 2rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 4rem;
  }
}

.mt-18 {
  margin-top: 2.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 4.5rem;
  }
}

.mt-20 {
  margin-top: 2.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-top: 5rem;
  }
}

// ********************
// MARGIN BOTTOM
// ********************

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 0.25rem;
  }
}

.mb-2 {
  margin-bottom: 0.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 0.5rem;
  }
}

.mb-3 {
  margin-bottom: 0.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 0.75rem;
  }
}

.mb-4 {
  margin-bottom: 0.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 1rem;
  }
}

.mb-5 {
  margin-bottom: 0.625rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 1.25rem;
  }
}

.mb-6 {
  margin-bottom: 0.75rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 1.5rem;
  }
}

.mb-7 {
  margin-bottom: 0.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 1.75rem;
  }
}

.mb-8 {
  margin-bottom: 1rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 2rem;
  }
}

.mb-9 {
  margin-bottom: 1.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 2.25rem;
  }
}

.mb-10 {
  margin-bottom: 1.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 2.5rem;
  }
}

.mb-11 {
  margin-bottom: 1.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 2.75rem;
  }
}

.mb-12 {
  margin-bottom: 1.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 3rem;
  }
}

.mb-14 {
  margin-bottom: 1.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 3.5rem;
  }
}

.mb-16 {
  margin-bottom: 2rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 4rem;
  }
}

.mb-18 {
  margin-bottom: 2.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 4.5rem;
  }
}

.mb-20 {
  margin-bottom: 2.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    margin-bottom: 5rem;
  }
}

// ********************
// PADDING Y
// ********************

.py-0 {
  padding: 0 0;
}

.py-1 {
  padding: 0.125rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 0.25rem 0;
  }
}

.py-2 {
  padding: 0.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 0.5rem 0;
  }
}

.py-3 {
  padding: 0.375rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 0.75rem 0;
  }
}

.py-4 {
  padding: 0.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 1rem 0;
  }
}

.py-5 {
  padding: 0.625rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 1.25rem 0;
  }
}

.py-6 {
  padding: 0.75rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 1.5rem 0;
  }
}

.py-7 {
  padding: 0.875rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 1.75rem 0;
  }
}

.py-8 {
  padding: 1rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 2rem 0;
  }
}

.py-9 {
  padding: 1.125rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 2.25rem 0;
  }
}

.py-10 {
  padding: 1.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 2.5rem 0;
  }
}

.py-11 {
  padding: 1.375rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 2.75rem 0;
  }
}

.py-12 {
  padding: 1.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 3rem 0;
  }
}

.py-14 {
  padding: 1.75rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 3.5rem 0;
  }
}

.py-16 {
  padding: 2rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 4rem 0;
  }
}

.py-18 {
  padding: 2.25rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 4.5rem 0;
  }
}

.py-20 {
  padding: 2.5rem 0;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding: 5rem 0;
  }
}

// ********************
// PADDING TOP
// ********************

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 0.25rem;
  }
}

.pt-2 {
  padding-top: 0.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 0.5rem;
  }
}

.pt-3 {
  padding-top: 0.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 0.75rem;
  }
}

.pt-4 {
  padding-top: 0.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 1rem;
  }
}

.pt-5 {
  padding-top: 0.625rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 1.25rem;
  }
}

.pt-6 {
  padding-top: 0.75rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 1.5rem 0;
  }
}

.pt-7 {
  padding-top: 0.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 1.75rem;
  }
}

.pt-8 {
  padding-top: 1rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 2rem;
  }
}

.pt-9 {
  padding-top: 1.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 2.25rem;
  }
}

.pt-10 {
  padding-top: 1.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 2.5rem;
  }
}

.pt-11 {
  padding-top: 1.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 2.75rem;
  }
}

.pt-12 {
  padding-top: 1.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 3rem;
  }
}

.pt-14 {
  padding-top: 1.75rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 3.5rem;
  }
}

.pt-16 {
  padding-top: 2rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 4rem;
  }
}

.pt-18 {
  padding-top: 2.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 4.5rem;
  }
}

.pt-20 {
  padding-top: 2.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 5rem;
  }
}

.pt-36 {
  padding-top: 4.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-top: 9rem;
  }
}

// ********************
// PADDING BOTTOM
// ********************

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 0.25rem;
  }
}

.pb-2 {
  padding-bottom: 0.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 0.5rem;
  }
}

.pb-3 {
  padding-bottom: 0.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 0.75rem;
  }
}

.pb-4 {
  padding-bottom: 0.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 1rem;
  }
}

.pb-5 {
  padding-bottom: 0.625rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 1.25rem;
  }
}

.pb-6 {
  padding-bottom: 0.75rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 1.5rem;
  }
}

.pb-7 {
  padding-bottom: 0.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 1.75rem;
  }
}

.pb-8 {
  padding-bottom: 1rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 2rem;
  }
}

.pb-9 {
  padding-bottom: 1.125rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 2.25rem;
  }
}

.pb-10 {
  padding-bottom: 1.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 2.5rem;
  }
}

.pb-11 {
  padding-bottom: 1.375rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 2.75rem;
  }
}

.pb-12 {
  padding-bottom: 1.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 3rem;
  }
}

.pb-14 {
  padding-bottom: 1.875rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 3.5rem;
  }
}

.pb-16 {
  padding-bottom: 2rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 4rem;
  }
}

.pb-18 {
  padding-bottom: 2.25rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 4.5rem;
  }
}

.pb-20 {
  padding-bottom: 2.5rem;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    padding-bottom: 5rem;
  }
}
