// ********************
// PAGE
// ********************

#hero--page {
  &::before {
    content: "";
    position: absolute;
    z-index: 50;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($cello, 0.4);
  }

  background-image: url("static/img/banff-hero.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  > * {
    position: relative;
    z-index: 100;
  }

  h1 {
    color: $white;
  }
}

.content__leaf {
  position: absolute;
  width: 15rem;
  height: auto;
  left: -2.5rem;
  top: 10rem;
  opacity: 0.5;
  visibility: hidden;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    visibility: visible;
  }
}

.content__moose {
  position: absolute;
  width: 12rem;
  height: auto;
  right: -1rem;
  top: 50%;
  visibility: hidden;

  @media screen and (min-width: map-get($breakpoints, "large")) {
    visibility: visible;
  }
}
